<template>
  <div>
    <titulo-relatorio-irmaos />
    <b-row>
      <b-col cols="12" md="3">
        <input-select
          ref="anoLetivoId"
          v-model="anoLetivoId"
          :label="$t('ACADEMICO.PERIODO_LETIVO')"
          :options="opcoes.anosLetivos"
          required
        />
      </b-col>
      <b-col cols="12" md="auto">
        <b-button
          class="w-100 botao-acao-filtro mt-3"
          variant="info"
          @click="realizarImpressao"
        >
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
  
  <script>

// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import MatriculasService from '@/common/services/matriculas/matriculas.service';

// Components:
import { InputSelect } from '@/components/inputs';
import mensagem from '@/common/utils/mensagem';
import TituloRelatorioIrmaos from '@/views/academico/relatorios/relatorio-irmaos/TituloRelatorioIrmaos';

export default {
  name: 'RelatorioIrmaos',
  components: {
    TituloRelatorioIrmaos,
    InputSelect,
  },
  data() {
    return {
      anoLetivoId: '',
      opcoes: {
        anosLetivos: [],
      },
    };
  },

  mounted() {
    this.getAnosLetivos();
  },

  methods: {
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    realizarImpressao() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);

      MatriculasService.gerarRelatorioIrmaosPorAnoLetivoId(this.anoLetivoId)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.ano,
              value: row.id,
            };
          });

          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
  
  <style>
.ajuste-fino-btn {
  margin-top: 26px;
}
</style>
  